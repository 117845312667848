<template>
  <div class="row justify-content-center mt-5">
    <div class="col-4 text-center border border-secondary">
      Pas encore de donnée !
    </div>
  </div>
</template>

<script>
export default {
  name: 'noDataInfo'
};
</script>

<style scoped>

</style>
