import auth from '@feathersjs/authentication-client';
import {iff, discard} from 'feathers-hooks-common';
import feathersVuex from 'feathers-vuex';

import {url} from './constant';

const feathers = require('@feathersjs/feathers');
const rest = require('@feathersjs/rest-client');
const restClient = rest(url);
const axios = require('axios');

const feathersClient = feathers()
  .configure(restClient.axios(axios))
  .configure(auth({storage: window.localStorage}))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  });

export default feathersClient;

// Setting up feathers-vuex
const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel,
  models,
  FeathersVuex
} = feathersVuex(feathersClient, {
  serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
  idField: '_id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options']
});

export {makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex};
