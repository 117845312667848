<template>
  <div class="mt-5 container">
    <div class="card shadow bg-custom-1">
      <div class="card-body mt-1">
        <div class="col">
          <ReturnMenuButton/>
        </div>
        <div class="col">
          <div class="row justify-content-center">
            <div class="col text-center">
              <h1>Liste des parties</h1>
              <span v-if="data && data !== false">Export Excel  <b-icon-download @click="getcsv"/></span>
            </div>
          </div>
        </div>
        <div v-if="data === false">
          <no-data-info/>
        </div>
        <div v-else-if="!data" class="mt-5">
          Chargement des donnée
        </div>
        <div v-else class="mt-5 table-responsive h-100">
          <table class="table table-bordered table-striped">
            <thead class="thead-light">
            <tr>
              <th v-if="$store.state.auth.user.super_admin" scope="col">Banque</th>
              <th v-if="$store.state.auth.user.banqueId === null || $store.state.auth.user.banqueId === 5" scope="col">N' d'agence        <b-icon-filter @click="sort('agence.code_agence')"/></th>
              <th scope="col">Agence        <b-icon-filter @click="sort('agence.name')"/></th>
              <th scope="col">Pseudo</th>
              <th scope="col">Score final
                <b-icon-filter @click="sort('score')"/>
              </th>
              <th scope="col">Badges obtenus</th>
              <th scope="col">Temps Total</th>
              <th scope="col">Date de début</th>
              <th scope="col">Terminé
                <b-icon-filter @click="sort('finish')"/>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in max()" :key="i">
              <th v-if="$store.state.auth.user.super_admin" scope="row">{{ sortedCats[i + add]['banques.name'] }}</th>
              <th v-if="$store.state.auth.user.banqueId === null || $store.state.auth.user.banqueId === 5" scope="row">
                {{ sortedCats[i + add].banqueId === 5 ? sortedCats[i + add]['agence.code_agence'] : "NA"}}
              </th>
              <th scope="row">{{ sortedCats[i + add]['agence.name'] }}</th>
              <th scope="row">{{ sortedCats[i + add].pseudo }}</th>
              <th scope="row">{{ sortedCats[i + add].score }}</th>
              <th scope="row">
                <ul>
                  <div v-for="badge in Object.entries(sortedCats[i+ add].badge)" :key="badge[0]">
                    <li v-if="badge[1]">
                      {{ badge[0] }}
                    </li>
                  </div>
                </ul>
              </th>
              <th scope="row">{{ sortedCats[i + add].time_total }}</th>
              <th scope="row">{{ date((sortedCats[i + add].created_date_player).replace('T', ' à ')) }}</th>
              <th scope="row">
                <b-icon-check scale="2" v-if="sortedCats[i+ add].finish"/>
                <b-icon-x scale="2" v-else/>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="data" class="row justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-text="<<"
            prev-text="<"
            next-text=">"
            last-text=">>"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReturnMenuButton from '../components/ReturnMenuButton';
import feathersClient from '../feathers-client';
import noDataInfo from '../components/noDataInfo';

export default {
  name: 'ConsulteParty',
  components: {ReturnMenuButton, noDataInfo},
  async created() {
    await this.getData();
  },
  methods: {
    date(str) {
      return (str.replace('T', ' à ')).slice(0, str.replace('T', ' à ').lastIndexOf('.'));
    },
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    async getData() {
      const temp = await feathersClient.service('player').find({
        query: {
          $limit: 1000
        }
      });
      if (temp.total > 0) {
        this.data = temp.data;
        this.total = temp.total;
      } else {
        this.data = false;
      }
    },
    formatBadge(obj) {
      let fileString = '';
      const temp = Object.entries(obj);
      temp.map(elm => {
        if (elm[1]) {
          fileString += elm[0] + ' ';
        }
      });
      return fileString;
    },
    formatData() {
      const formatData = [];
      this.data.map((elm) => {
        if (this.$store.state.auth.user.super_admin) {
          const obj = {
            Banque: elm['banques.name'],
            'N agence': elm['agence.code_agence'],
            Agence: elm['agence.name'],
            Pseudo: elm.pseudo,
            Score: elm.score,
            Badges: this.formatBadge(elm.badge),
            Temps: elm.time_total,
            'Date de début': ((elm.created_date_player).replace('T', ' à ')).slice(0, (elm.created_date_player).replace('T', ' à ').lastIndexOf('.') + 1),
            Terminé: elm.finish
          };
          formatData.push(obj);
        } else {
          const obj = {
            'N agence': elm['agence.code_agence'],
            Agence: elm['agence.name'],
            Pseudo: elm.pseudo,
            Score: elm.score,
            Badges: elm.badge,
            Temps: elm.time_total,
            'Date de début': ((elm.created_date_player).replace('T', ' à ')).slice(0, (elm.created_date_player).replace('T', ' à ').lastIndexOf('.') + 1),
            Terminé: elm.finish
          };
          formatData.push(obj);
        }
      });
      return formatData;
    },
    async getcsv() {
      const data = this.formatData();
      const temp = await feathersClient.service('export-csv').create(data, {
        headers: {responseType: 'arraybuffer'}
      });
      const url = window.URL.createObjectURL(new Blob([temp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    },
    max() {
      if (this.total - (this.perPage + this.add + 1) >= 0) {
        return this.perPage;
      } else {
        return this.perPage - (this.total - (this.perPage + this.add + 1)) * -1;
      }
    }
  },
  computed: {
    add() {
      return ((this.currentPage - 1) * this.perPage) - 1;
    },
    sortedCats: function() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return modifier;
        return 0;
      });
    }
  },
  setup(props, context) {
    const data = null;
    const currentPage = 1;
    const perPage = 5;
    const total = 0;
    const currentSort = 'pseudo';
    const currentSortDir = 'asc';

    return {
      total,
      data,
      currentPage,
      perPage,
      currentSort,
      currentSortDir
    };
  }
};
</script>

<style scoped>

</style>
