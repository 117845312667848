<template>
  <div class="mt-5 container">
    <div class="card shadow bg-custom-1">
      <div class="card-body mt-1">
        <div class="col">
          <ReturnMenuButton/>
        </div>
        <div class="col">
          <div class="row justify-content-center">
            <div class="col text-center">
              <h1>Gestion des profils</h1>
              <span v-if="!generate">Sélectionner un profil </span>
            </div>
          </div>
        </div>
        <div v-if="!users" class="mt-5">
          Chargement des données
        </div>
        <div class="row mt-5 justify-content-center" v-else-if="!generate">
          <div class="col-8">
          <div class="list-group ">
            <div v-for="elm in users" :key="elm.id">
              <input  type="radio" :id="elm.id" name="optradio" :value="elm.id" v-model="selectUser">
              <label class="list-group-item list-group-item-action" :for="elm.id">{{ elm.username }}</label>
            </div>
          </div>
            <div v-if="selectUser" class="row justify-content-center mt-4 mb-4">
              <button class="btn btn-primary" @click="changeMdp">Générer un nouveau mot de passe</button>
            </div>
          </div>
        </div>
        <div class="row mt-5 justify-content-center" v-else>
          <div class="col-8 text-center">
            <div>
              <h3>{{getNameBanque()}}</h3>
            </div>
            <div class="mt-4">
              <p>Le mot de passe de {{getNameBanque()}} à bien été modifé</p>
            </div>
            <div class="mt-5 mb-3">
              <p>Code: <span class="font-weight-bold">{{this.ret}}</span></p>
            </div>
            <div class="text-muted">
              <p>Pensez à noter le mot de passe avant de quitter cette page</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnMenuButton from '../components/ReturnMenuButton';
import feathersClient from '../feathers-client';

export default {
  name: 'GestionProfile',
  components: {ReturnMenuButton},
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        const users = await feathersClient.service('users').find();
        if (users.total > 0) {
          this.users = users.data;
        } else {
          this.noData = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    getNameBanque() {
      return (this.users.filter((elm) => {
        return elm.id === this.selectUser;
      }))[0].username;
    },
    async changeMdp() {
      if (this.selectUser) {
        try {
          this.ret = await feathersClient.service('regenerate-banque').patch(this.selectUser, {});
          this.generate = true;
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  setup(props, context) {
    const users = null;
    const selectUser = null;
    const generate = false;
    const ret = null;
    return {
      users,
      selectUser,
      generate,
      ret
    };
  }
};
</script>

<style scoped>
.list-group-item {
  user-select: none;
}

.list-group input[type="checkbox"] {
  display: none;
}

.list-group input[type="checkbox"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="checkbox"] + .list-group-item:before {
  content: "\2713";
  color: transparent;
  font-weight: bold;
  margin-right: 1em;
}

.list-group input[type="checkbox"]:checked + .list-group-item {
  background-color: #0275D8;
  color: #FFF;
}

.list-group input[type="checkbox"]:checked + .list-group-item:before {
  color: inherit;
}

.list-group input[type="radio"] {
  display: none;
}

.list-group input[type="radio"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="radio"] + .list-group-item:before {
  content: "\2022";
  color: transparent;
  font-weight: bold;
  margin-right: 1em;
}

.list-group input[type="radio"]:checked + .list-group-item {
  background-color: #0275D8;
  color: #FFF;
}

.list-group input[type="radio"]:checked + .list-group-item:before {
  color: inherit;
}
</style>
