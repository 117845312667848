<template>
  <div class="mt-5 container">
    <div class="card shadow bg-custom-1">
      <div class="card-body mt-1">
        <div class="col">
          <ReturnMenuButton/>
        </div>
        <div class="col">
          <div class="row justify-content-center">
            <div class="col text-center">
              <h1>Avis généraux</h1>
              <span v-if="data && data !== false">Export Excel  <b-icon-download @click="getcsv"/></span>
            </div>
          </div>
        </div>
        <div v-if="data === false">
          <no-data-info/>
        </div>
        <div v-else-if="!data" class="mt-5">
          Chargement des donnée
        </div>
        <div v-else class="mt-5 table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-light">
            <tr>
              <th v-if="$store.state.auth.user.super_admin" scope="col">Banque</th>
              <th scope="col"> Pseudo</th>
              <th scope="col">Opinion</th>
              <th scope="col">Note (/5)    <b-icon-filter @click="sort('satisfaction_score')"/></th>
              <th scope="col">N' d'agence</th>
              <th scope="col">Agence</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in max()" :key="i">
              <th v-if="$store.state.auth.user.super_admin" scope="row">{{ sortedCats[i+ add]['banques.name'] }}</th>
              <th scope="row">{{ sortedCats[i+ add].pseudo }}</th>
              <th scope="row">{{ sortedCats[i+ add].satisfaction_log }}</th>
              <th scope="row">{{ sortedCats[i+ add].satisfaction_score }}</th>
              <th scope="row">{{ sortedCats[i+ add]['agence.code_agence'] }}</th>
              <th scope="row">{{ sortedCats[i+ add]['agence.name'] }}</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-if="data" class="row justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-text="<<"
            prev-text="<"
            next-text=">"
            last-text=">>"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReturnMenuButton from '../components/ReturnMenuButton';
import feathersClient from '../feathers-client';
import noDataInfo from '../components/noDataInfo';

export default {
  name: 'ConsulteAvis',
  components: {ReturnMenuButton, noDataInfo},
  async created() {
    await this.getData();
  },
  methods: {
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    async getData() {
      try {
        const temp = await feathersClient.service('player').find({
          query: {
            finish: true,
            $limit: 1000
          }
        });
        if (temp.total > 0) {
          this.data = temp.data;
          this.total = temp.total;
        } else {
          this.data = false;
        }
      } catch (e) {
        console.log(e);
      }
    },

    formatData() {
      const formatData = [];
      this.data.map((elm) => {
        if (this.$store.state.auth.user.super_admin) {
          const obj = {
            Banque: elm['banques.name'],
            Pseudo: elm.pseudo,
            Opinion: elm.satisfaction_log,
            Note: elm.satisfaction_score,
            'N agence': elm['agence.code_agence'],
            Agence: elm['agence.name']
          };
          formatData.push(obj);
        } else {
          const obj = {
            Pseudo: elm.pseudo,
            Opinion: elm.satisfaction_log,
            Note: elm.satisfaction_score,
            'N agence': elm['agence.code_agence'],
            Agence: elm['agence.name']
          };
          formatData.push(obj);
        }
      });
      return formatData;
    },

    async getcsv() {
      const data = this.formatData();
      const temp = await feathersClient.service('export-csv').create(data, {
        headers: {responseType: 'arraybuffer'}
      });
      const url = window.URL.createObjectURL(new Blob([temp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);
      link.click();
    },
    max() {
      if (this.total - (this.perPage + this.add + 1) >= 0) {
        return this.perPage;
      } else {
        return this.perPage - (this.total - (this.perPage + this.add + 1)) * -1;
      }
    }
  },
  computed: {
    add() {
      return ((this.currentPage - 1) * this.perPage) - 1;
    },
    sortedCats: function() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return modifier;
        return 0;
      });
    }
  },
  setup(props, context) {
    const data = null;
    const currentPage = 1;
    const perPage = 5;
    const total = 0;
    const currentSort = 'pseudo';
    const currentSortDir = 'asc';
    return {
      data,
      currentPage,
      perPage,
      total,
      currentSort,
      currentSortDir
    };
  }
};
</script>

<style scoped>

</style>
