<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {onMounted, watch} from '@vue/composition-api';

export default {
  name: 'App',
  setup(props, context) {
    const {$store, $router} = context.root;
    watch(
      () => $store.state.auth.user,
      user => {
        const toRouteName = user ? 'Home' : 'Login';
        if (toRouteName !== $router.history.current.name &&
          ($router.history.current.name === 'Home' || $router.history.current.name === 'Login')) {
          $router.replace({name: toRouteName});
          return;
        }
        if ($router.history.current.name === 'GestionProfile' && !$store.state.auth.user.super_admin) {
          $router.replace('Home');
        }
      },
      {lazy: true}
    );

    onMounted(() => {
      $store.dispatch('auth/authenticate').catch(error => {
        if (!error.message.includes('Could not find stored JWT') && $router.history.current.name !== 'Login') {
          $router.replace({name: 'Login'});
        }
      });
    });

    return {};
  }
};
</script>

<style>
body {
  background: #87CEFA;
}

.bg-custom-1 {
  background-color: #e1e5ea;
}

.bg-custom-2 {
  background-color: #f2dac3;
}

.card {
  min-height: 500px;
}
</style>
