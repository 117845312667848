<template>
  <div class="mt-5 container">
    <div class="card shadow text-center bg-custom-1">
      <div class="card-body mt-1">
        <img class="card-img-top" src="https://bo.mission-renovation.pepz.fr/Webp.net-resizeimage.png" alt="Card image cap">
        <h4 class="card-title font-weight-bold mt-3">
          Authentification
        </h4>
        <div :class="[error ? 'mt-1' : 'mt-5']">
          <div v-if="error" class="alert alert-danger" role="alert">
            Le mot de passe ou l'identifiant est incorrect
          </div>
          <form id="stat" @submit.prevent="onSubmit(username, password)">
            <div class="form-group row justify-content-center">
              <label for="username" class="col-sm-2 col-form-label">Identifiant</label>
              <div class="col-sm-3">
                <input type="text" class="form-control" id="username" v-model="username">
              </div>
            </div>
            <div class="form-group row justify-content-center">
              <label for="password" class="col-sm-2 col-form-label">Mot de passe</label>
              <div class="col-sm-3">
                <input type="password" class="form-control" id="password" v-model="password">
              </div>
            </div>
            <button type="submit" class="btn bg-custom-2 mt-2 pl-5 pr-5 mb-4">Se connecter</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from '@vue/composition-api';

export default {
  name: 'LoginPage',
  setup(props, context) {
    // eslint-disable-next-line no-unused-vars
    const {$store} = context.root;
    const username = ref('');
    const password = ref('');
    const error = ref(false);

    async function onSubmit(username, password) {
      try {
        await $store.dispatch('auth/authenticate', {
          strategy: 'local',
          username,
          password
        });
      } catch (err) {
        this.error = true;
      }
    }

    return {
      username,
      password,
      error,
      onSubmit
    };
  }
};
</script>

<style>
.card-img-top {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}
</style>
