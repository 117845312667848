<template>
  <div class="mt-5 container">
    <div class="card shadow bg-custom-1">
      <div class="card-body mt-1">
        <div class="row justify-content-end">
          <div class="col-md-4 text-center">
            <h1>Administration</h1>
            <p>Que souhaitez-vous faire ?</p>
          </div>
          <div class="col-md-3 offset-sm-1">
            <button class="btn pl-5 pr-5 bg-custom-2" @click="logout">Déconnexion</button>
          </div>
        </div>
        <div class="mt-5 mb-5">
          <div class="row justify-content-center">
            <div class="mt-3">
              <button class="btn pl-5 pr-5 bg-custom-3" style="min-width: 350px" @click="goTo('GenerationStats')">Générer des statistiques</button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="mt-3">
              <button class="btn pl-5 pr-5 bg-custom-3" style="min-width: 350px" @click="goTo('ConsulteAvis')">Consulter
                les avis des joueurs
              </button>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="mt-3">
              <button class="btn pl-5 pr-5 bg-custom-3" style="min-width: 350px" @click="goTo('ConsulteParty')">
                Consulter les parties
              </button>
            </div>
          </div>
          <div v-if="$store.state.auth.user.super_admin" class="row justify-content-center">
            <div class="mt-3">
              <button class="btn pl-5 pr-5 btn-info" style="min-width: 350px" @click="goTo('GestionProfile')">
                Gestion des profils
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {ref} from '@vue/composition-api';

export default {
  name: 'Home',
  setup(props, context) {
    const {$store, $router} = context.root;

    function logout() {
      $store.dispatch('auth/logout');
      $router.replace('/Login');
    }

    function goTo(RouteName) {
      $router.replace({name: RouteName});
    }

    return {
      logout,
      goTo
    };
  }
};
</script>

<style>
.bg-custom-3 {
  background: coral;
}

</style>
