<template>
  <div class="mt-5 container">
    <div class="card shadow bg-custom-1">
      <div class="card-body mt-1">
        <div v-if="status !== 1" class="col">
          <ReturnMenuButton/>
        </div>
        <div v-else class="col">
          <b-button size="sm" class="mb-2" @click="status = null" >
            <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Retour
          </b-button>
        </div>
        <div class="col">
          <div class="row justify-content-center">
            <div class="col text-center">
              <h1>Générer des statistiques</h1>
              <div v-if="status === 1">
                <span>Export Excel  <b-icon-download @click="getcsv"/></span>
                <p class="text-muted mt-2">
                  Attention, si vous quittez cette page sans télécharger le fichier, les données générées seront perdues et il vous faudra refaire l'action
                </p>
              </div>
            </div>
          </div>
        </div>
        <form v-if="status === null" id="stat" @submit.prevent="getStatistique">
          <div class="form-group mt-3">
            <div class="form-row justify-content-around">
              <div class="form-check form-check-inline">
                <input class="form-check-input" value=1 type="radio" name="inlineRadioOptions" id="inlineRadio1"
                       v-model="dataForm.type" required>
                <label class="form-check-label" for="inlineRadio1">Volume de joueur</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" value=2 type="radio" name="inlineRadioOptions" id="inlineRadio2"
                       v-model="dataForm.type">
                <label class="form-check-label" for="inlineRadio2">Répartition Géographique</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" value=3 type="radio" name="inlineRadioOptions" id="inlineRadio3"
                       v-model="dataForm.type">
                <label class="form-check-label" for="inlineRadio3">Taux de complétion</label>
              </div>
            </div>
            <div class="form-row justify-content-center mt-4">
              <div class="col-4">
                <div class="row">
                  <label class="form-check-label mr-3" for="startD">Date de début</label>
                  <datepicker :format="'dd-MM-yyyy'" id="startD" name="startD" v-model="dataForm.startDate"/>
                </div>
                <div class="row mt-2">
                  <label class="form-check-label mr-2 pt-1" for="startH">Heure de début</label>
                  <vue-timepicker hide-clear-button id="startH" v-model="dataForm.startHour"/>
                </div>
              </div>
              <div class="col-4">
                <div class="row">
                  <label class="form-check-label mr-3" for="startE">Date de fin</label>
                  <datepicker :format="'dd-MM-yyyy'" id="startE" name="startE" v-model="dataForm.endDate"></datepicker>
                </div>
                <div class="row mt-2">
                  <label class="form-check-label mr-2 pt-1" for="endH">Heure de fin</label>
                  <vue-timepicker hide-clear-button id="endH" v-model="dataForm.endHour"/>
                </div>
              </div>
            </div>
            <div class="form-group row justify-content-center mt-4">
              <div>
                <label class="form-check-label mr-2" for="inputState">Type de partie</label>
              </div>
              <div class="col-6">
                <select id="inputState" class="form-control" v-model="dataForm.typeParty" required>
                  <option value="1">Tous</option>
                  <option value="2">Partie terminée</option>
                  <option value="3">Partie commencée</option>
                </select>
              </div>
            </div>
            <div v-if="$store.state.auth.user && $store.state.auth.user.super_admin"
                 class="form-row justify-content-center mt-4">
              <div>
                <label class="form-check-label mr-2" for="banqueState">Banque</label>
              </div>
              <div class="col-6">
                <select id="banqueState" class="form-control" v-model="test" required>
                  <option v-for="elm in this.listBanque" :value="elm.id" :key="elm.id">{{ elm.name }}</option>
                  <option :value="-1">Tous</option>
                </select>
              </div>
            </div>
            <div v-if="test !== -1" class="form-row justify-content-center mt-4">
              <div>
                <label class="form-check-label mr-2" for="agence">Agence</label>
              </div>
              <div v-if="listAgence" class="col-6">
                <select id="agence" class="form-control" v-model="dataForm.agenceId" required>
                  <option v-for="elm in this.listAgence.data" :key="elm.id" :value="elm.id">{{ elm.name }}</option>
                  <option :value="-1">Tous</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <button type="submit" class="btn btn-primary mt-2">Obtenir</button>
          </div>
        </form>
        <div v-else class="row justify-content-center mt-5">
          <p>Votre fichier a été généré
            <b-icon-download class="ml-5" scale="2" @click="getcsv"/>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReturnMenuButton from '../components/ReturnMenuButton';
import Datepicker from 'vuejs-datepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueTimepicker from 'vue2-timepicker';
import feathersClient from '../feathers-client';

export default {
  name: 'Statistique',
  components: {ReturnMenuButton, Datepicker, VueTimepicker},
  async created() {
    await this.getListBanque();
    await this.getAgence();
  },
  methods: {
    async getListBanque() {
      const temp = await feathersClient.service('banque').find({query: {all: true}});
      if (temp.total > 0) {
        this.listBanque = temp.data;
      } else {
        this.listBanque = false;
      }
    },
    async getStatistique() {
      this.status = 1;
      this.dataForm.banqueId = this.test;
      this.dataForm.startDate = new Date(this.dataForm.startDate).toISOString().split('T')[0] + 'T' + this.dataForm.startHour;
      this.dataForm.endDate = new Date(this.dataForm.endDate).toISOString().split('T')[0] + 'T' + this.dataForm.endHour;
      const temp = await feathersClient.service('statistiques').create(this.dataForm);
      if (temp) {
        this.data = temp;
      } else {
        this.data = false;
      }
    },
    async getcsv() {
      const temp = await feathersClient.service('export-csv').create((this.data), {
        headers: {responseType: 'arraybuffer'}
      });
      const url = window.URL.createObjectURL(new Blob([temp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export_statistique.csv');
      document.body.appendChild(link);
      link.click();
    },
    async getAgence() {
      const temp = await feathersClient.service('list-agence').find({
        query: {
          banqueId: this.test
        }
      });
      if (temp) {
        this.listAgence = temp;
      } else {
        this.listAgence = false;
      }
    }
  },
  watch: {
    test: async function(val, oldVal) {
      if (val !== -1 && val !== oldVal) {
        await this.getAgence();
      }
    }
  },
  setup(props, context) {
    const status = null;
    const listAgence = null;
    const listBanque = null;
    const test = 1;
    const data = null;
    const dataForm = {
      type: null,
      startDate: Date.now(),
      endDate: Date.now(),
      typeParty: 1,
      banqueId: -1,
      agenceId: -1,
      startHour: '00:00',
      endHour: '12:00'
    };

    return {
      dataForm,
      status,
      listBanque,
      listAgence,
      data,
      test
    };
  }
};
</script>

<style scoped>

</style>
