<template>
  <b-button size="sm" class="mb-2" @click="backToHome" >
    <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Retour
  </b-button>
</template>

<script>
export default {
  name: 'ReturnMenuButton',
  props: {
    type: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, context) {
    const {$router} = context.root;

    function backToHome() {
      if (!this.type) {
        $router.replace({name: 'Home'});
      } else {
        $router.replace({name: 'GenerationStats'});
      }
    }

    return {
      backToHome
    };
  }
};
</script>

<style scoped>

</style>
