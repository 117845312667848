import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login';
import ConsulteParty from '../views/ConsulteParty';
import ConsulteAvis from '../views/ConsulteAvis';
import GestionProfile from '../views/GestionProfile';
import Statistique from '../views/Statistique';
// import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/consulter-les-parties',
    name: 'ConsulteParty',
    component: ConsulteParty,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/consulter-les-avis',
    name: 'ConsulteAvis',
    component: ConsulteAvis,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/gestion-des-profiles',
    name: 'GestionProfile',
    component: GestionProfile,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true
    }
  },
  {
    path: '/statistique',
    name: 'GenerationStats',
    component: Statistique,
    meta: {
      requiresAuth: true
    }
  },
  /* {
     path: '/classement',
     name: 'Classement',
     component: Classement,
     meta: {
       requiresAuth: true
     }
   }, */
  {
    path: '*',
    name: 'HomeNotFound',
    component: Home,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
